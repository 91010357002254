import { useCallback, useEffect, useMemo, useState } from 'react'

import { ConfirmModal, TextField } from '@nexds/web'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { drawerModalSubject, globalModalSubject, ModalLocale, ModalUtil } from '@/utils/modalUtil'

import { Backdrop, InputsContainer, ModalContainer } from './ModalPresenter.styles'

interface ModalPresenterProps {
  locale: ModalLocale
}

function ModalPresenter(props: ModalPresenterProps) {
  const { locale } = props

  const { t } = useI18n()

  const modalList = useBehaviorSubject(locale === 'drawer' ? drawerModalSubject : globalModalSubject)
  const currentModal = modalList[0]

  const hideModal = ModalUtil[locale].hide

  const defaultPositiveLabel = useMemo(() => t('MODAL_BUTTON_DEFAULT_CONFIRM'), [])

  const [inputValues, setInputValues] = useState(currentModal?.inputs?.map((input) => input.value || '') ?? [])

  const handleInputChange = useCallback(
    (index: number, value: string) => {
      const newValues = [...inputValues]
      newValues[index] = value
      setInputValues(newValues)
      currentModal.inputs?.[index].onChange?.(value)
    },
    [inputValues, currentModal]
  )

  const handleClose = useCallback(() => {
    hideModal(currentModal.id)
    if (currentModal.onClose) currentModal.onClose()
    setInputValues(currentModal.inputs?.map((input) => input.value || '') ?? [])
  }, [currentModal])

  if (modalList.length === 0) return null
  return (
    <ModalContainer>
      <Backdrop onClick={handleClose} />

      <ConfirmModal
        isOpen={modalList.length > 0}
        title={currentModal.title}
        description={currentModal.headerDescription}
        hasDescription={!!currentModal.headerDescription}
        positiveLabel={currentModal.positiveLabel ?? defaultPositiveLabel}
        negativeLabel={currentModal.negativeLabel}
        positiveColor={currentModal.positiveColor}
        buttonsSize="xs"
        maxWidth={currentModal.maxWidth}
        maxHeight={currentModal.maxHeight}
        hasCloseButton={currentModal.hasCloseButton}
        onPositive={() => {
          const mappedValues = currentModal.inputs?.map((input, index) => ({
            id: input.id,
            value: inputValues[index] ?? ''
          }))
          hideModal(currentModal.id)
          if (currentModal.onPositive) {
            currentModal.onPositive(mappedValues)
          }
          currentModal.inputs?.forEach((_, index) => {
            handleInputChange(index, '')
          })
        }}
        onNegative={() => {
          hideModal(currentModal.id)
          currentModal.onNegative?.()
          currentModal.inputs?.forEach((_, index) => {
            handleInputChange(index, '')
          })
        }}
        onClose={handleClose}
      >
        {currentModal?.inputs && (
          <InputsContainer>
            {currentModal?.inputs?.map((input, index) => (
              <TextField
                key={input.id}
                label={input.label}
                placeholder={input.placeholder}
                value={input.valueToUpperCase ? inputValues[index]?.toUpperCase() ?? '' : inputValues[index] ?? ''}
                size="sm"
                helpGutter={false}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
            ))}
          </InputsContainer>
        )}
        {currentModal.content}
      </ConfirmModal>
    </ModalContainer>
  )
}

export { ModalPresenter }
