import { inject, injectable } from 'inversify'
import Cookies from 'js-cookie'
import { interval, Subscription, switchMap } from 'rxjs'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import type { ILogger } from '@/infra/logger/ILogger'
import { ModalUtil } from '@/utils/modalUtil'

import { IService } from '../IService'

@injectable()
export class Authenticator implements IService {
  private _subscriptionChecker!: Subscription
  constructor(
    @inject(InjectionTokens.AuthState) private authState: AuthState,
    @inject(InjectionTokens.Logger) private logger: ILogger
  ) {}

  async init(): Promise<Result<void>> {
    const authToken = this._getAuthenticationCookie()
    if (!authToken) {
      return Result.fail('AUTHENTICATION_COOKIE_NOT_FOUND')
    } else {
      this.authState.setToken(authToken)
    }

    return Result.ok()
  }
  async start(): Promise<Result<void>> {
    if (!this._checkSubscriptionStatus()) {
      return Result.fail('SUBSCRIPTION_ERROR')
    }

    this._subscriptionChecker = interval(1000 * 60 * 5) // 5 minutos
      .pipe(switchMap(async () => this._checkSubscriptionStatus()))
      .subscribe((status) => {
        if (!status) {
          // TODO: i18n global
          ModalUtil.global.show({
            title: 'Há um problema com a sua assinatura!',
            headerDescription: 'Estamos te redirecionando para solucionar o problema',
            positiveLabel: 'OK',
            onPositive: () => (document.location = import.meta.env.VITE_SUBSCRIPTION_MANAGER_URL)
          })
        }
      })

    return Result.ok()
  }

  stop(): Result<void> {
    if (this._subscriptionChecker) {
      this._subscriptionChecker.unsubscribe()
      return Result.ok()
    } else {
      return Result.fail('SubscriptionChecker not found')
    }
  }

  private _getAuthenticationCookie() {
    const natCookie = Cookies.get('_nat')
    return natCookie ?? null
  }

  private _checkSubscriptionStatus(): boolean {
    const currentUser = this.authState.getStateSnapshot().user
    const inTrialCheck = !currentUser?.trialChecked
    const inSubscriptionCheck = currentUser?.subscriptionId && !currentUser?.subscriptionChecked
    const subscriptionDown = ['unpaid', 'canceled', 'ended'].includes(currentUser?.subscriptionStatus ?? '')

    // Valid subscriptions
    const inTrial = !currentUser?.subscriptionId && new Date() <= new Date(currentUser?.trialTo) && !inTrialCheck
    const subscriptionDownButHaveTime =
      subscriptionDown && new Date() <= new Date(currentUser?.subscriptionPeriodEnd) && !inSubscriptionCheck
    const subscriptionOk =
      ['paid', 'pending_payment', 'waiting_payment'].includes(currentUser?.subscriptionStatus ?? '') &&
      !inSubscriptionCheck

    // Manager
    let managerOk = false
    if (currentUser?.managerId) {
      const managerSubscriptionDown = ['unpaid', 'canceled', 'ended'].includes(
        currentUser?.manager?.subscriptionStatus ?? ''
      )
      const managerInTrial =
        !currentUser?.manager?.subscriptionId && new Date() <= new Date(currentUser?.manager?.trialTo)
      const managerSubscriptionDownButHaveTime =
        managerSubscriptionDown && new Date() <= new Date(currentUser?.manager?.subscriptionPeriodEnd)
      const managerSubscriptionOk = ['paid', 'pending_payment', 'waiting_payment'].includes(
        currentUser?.manager?.subscriptionStatus ?? ''
      )

      managerOk = managerInTrial || managerSubscriptionDownButHaveTime || managerSubscriptionOk
    }

    if (inTrial || subscriptionDownButHaveTime || subscriptionOk || managerOk) {
      return true
    } else {
      return false
    }
  }
}
