import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 100vw;
  margin: 0 auto;

  text-align: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  ${({ theme }) => theme.fonts.h5}
`

export const ErrorTitle = styled.h1`
  ${({ theme }) => theme.fonts.titleLG}
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.fonts.infoLG}
  color: ${({ theme }) => theme.colors.neutralL5};
`
