import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import type { IHttpClient } from '../http/IHttpClient'
import { UserRemote, UserResponseMapper } from '../Mappers/authentication'
import { IAccountWebClient } from './IAccountWebClient'

interface CurrentUserResponse {
  user: UserRemote
  status: number
}

@injectable()
export class AccountWebClient implements IAccountWebClient {
  constructor(
    @inject(InjectionTokens.HttpClient) private httpClient: IHttpClient,
    @inject(InjectionTokens.AuthState) private authState: AuthState
  ) {}

  protected getRemoteUrl(): string {
    return `${import.meta.env.VITE_NEXATLAS_API_URL}/user`
  }

  async init(): Promise<Result<void>> {
    const response = await this.httpClient.post<CurrentUserResponse>(`${this.getRemoteUrl()}/getCurrentUser`, null, {
      headers: {
        Authorization: `Token ${this.authState.getStateSnapshot().token}`
      }
    })

    if (response.success === false) {
      return Result.fail('ACCOUNT_UNAVAILABLE')
    }

    if (response.status >= 400) {
      return Result.fail('ACCOUNT_TOKEN_ERROR')
    }

    const userMapped = UserResponseMapper(response.response.user)
    if (!userMapped) return Result.fail('ACCOUNT_TOKEN_ERROR')
    this.authState.setUser(userMapped)

    return Result.ok()
  }

  async logout(): Promise<Result<void>> {
    try {
      const response = await this.httpClient.post(
        `${this.getRemoteUrl()}/logout`,
        {
          token: this.authState.getStateSnapshot().token
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      return Result.ok()
    } catch (err) {
      return Result.fail(err)
    }
  }
}
