export enum appZIndex {
  ROUTER_GUIDE = 40,
  BANNER_FOOTER = 50,
  CHAT_CLOSED = 100,
  METEOROLOGY_CONTROL = 110,
  TOOLBAR = 200,
  INFO_PANEL = 300,
  TOOLBAR_SMALL_SCREEN = 510,
  DRAWER = 400,
  SEARCH_BOX = 450,
  MAP_SEARCH = 800,
  WAYPOINT_INFO = 800,
  TOOLTIP = 800,
  CHAT_OPEN = 800,
  MODAL = 1000,
  BANNER_DESKTOP = 1000,
  BANNER_MOBILE = 1000,
  GLOBAL_TOAST = 1000
}
