import { HttpBody, HttpOptions, HttpResponse, IHttpClient } from '@infra/http/IHttpClient'
import axios from 'axios'
import { injectable } from 'inversify'

@injectable()
export class AxiosHttpClient implements IHttpClient {
  async get<T>(url: string, options?: HttpOptions): Promise<HttpResponse<T>> {
    try {
      const axiosResponse = await axios.get<T>(url, {
        ...options,
        validateStatus: () => true
      })
      const plainHeaders = Object.fromEntries(axiosResponse.headers as any)

      return {
        success: true,
        status: axiosResponse.status,
        headers: plainHeaders,
        response: axiosResponse.data
      }
    } catch (error: any) {
      return {
        success: false,
        status: error.response?.status,
        error: new Error(error.message)
      }
    }
  }

  async post<T>(url: string, body: HttpBody, options?: HttpOptions): Promise<HttpResponse<T>> {
    try {
      const axiosResponse = await axios.post<T>(url, body, {
        ...options,
        validateStatus: () => true
      })
      const plainHeaders = Object.fromEntries(axiosResponse.headers as any)

      return {
        success: true,
        status: axiosResponse.status,
        headers: plainHeaders,
        response: axiosResponse.data
      }
    } catch (error: any) {
      return {
        success: false,
        status: error.response?.status,
        error: new Error(error.message)
      }
    }
  }

  async put<T>(url: string, body: HttpBody, options?: HttpOptions): Promise<HttpResponse<T>> {
    try {
      const axiosResponse = await axios.put(url, body, {
        ...options,
        validateStatus: () => true
      })
      const plainHeaders = Object.fromEntries(axiosResponse.headers as any)

      return {
        success: true,
        status: axiosResponse.status,
        headers: plainHeaders,
        response: axiosResponse.data
      }
    } catch (error: any) {
      return {
        success: false,
        status: error.response?.status,
        error: new Error(error.message)
      }
    }
  }

  async delete<T>(url: string, body: HttpBody, options?: HttpOptions): Promise<HttpResponse<T>> {
    try {
      const axiosResponse = await axios.delete(url, {
        ...options,
        data: body,
        validateStatus: () => true
      })

      const plainHeaders = Object.fromEntries(axiosResponse.headers as any)

      return {
        success: true,
        status: axiosResponse.status,
        headers: plainHeaders,
        response: axiosResponse.data
      }
    } catch (error: any) {
      return {
        success: false,
        status: error.response?.status,
        error: new Error(error.message)
      }
    }
  }
}
