import styled from 'styled-components'

interface ChartSelectorProps {
  isActive?: boolean
}

export const Container = styled.div`
  @media (max-width: 430px) {
    max-width: 60px;
  }
`

export const Input = styled.input<ChartSelectorProps>`
  display: none;

  &:focus ~ label {
    border: ${({ theme, isActive }) => (isActive ? theme.colors.secondaryL3 : 'transparent')};
  }
`

export const ChartSelectorButton = styled.label<ChartSelectorProps>`
  cursor: pointer;
  width: 80px;
  height: 120px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme, isActive }) => (isActive ? `${theme.colors.secondaryL1}bf` : 'transparent')};
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.secondaryL3 : 'transparent')};

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? `${theme.colors.secondaryL1}b3` : `${theme.colors.secondaryL1}26`};
  }

  &:active {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.secondaryL1 : `${theme.colors.secondaryL1}52`};
  }

  @media (max-width: 430px) {
    width: 70px;
    height: 110px;
  }

  @media (max-height: 800px) {
    width: 70px;
    height: 103px;
    padding: 4px 6px 0 6px;
  }
`

interface ChartImageProps {
  src: string
}

export const ChartImage = styled.div<ChartImageProps>`
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 10px;

  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 430px) {
    max-width: 58px;
    max-height: 58px;
    min-width: 58px;
    min-height: 58px;
  }

  /* @media (max-height: 800px) {
    max-width: 54px;
    max-height: 54px;
    min-width: 54px;
    min-height: 54px;
  } */
`

export const ChartLabel = styled.p`
  ${({ theme }) => theme.fonts.textSM}
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin: 4px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 430px) {
    ${({ theme }) => theme.fonts.infoXS}
  }

  /* @media (max-height: 800px) {
    margin-top: 3px;
  } */
`
