import 'mapbox-gl/dist/mapbox-gl.css'

import { useMemo } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { BaseMapType, MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'

import { MapView } from '../components/MapView/MapView'
import { MapADHPPresenter } from './MapADHPPresenter'
import { MapAirspacesPresenter } from './MapAirspacesPresenter'
import { MapChartsPresenter } from './MapChartsPresenter'
import { MapMeasuringSegmentPresenter } from './MapMeasuringSegmentPresenter'
import { MapMeteorologyPresenter } from './MapMeteorologyPresenter'
import { MapProcedurePresenter } from './MapProcedurePresenter'
import { MapRoutePresenter } from './MapRoutePresenter'
import { MapSearchPresenter } from './MapSearchPresenter'
import { MapStackPresenter } from './MapStackPresenter'
import { MapTargetPresenter } from './MapTargetPresenter'
import { TrackHeadboardPresenter } from './TrackHeadboardPresenter'

function MapPresenter() {
  const [mapLayerState] = useGlobalState<MapLayerState, MapLayerStateProps>(InjectionTokens.MapLayerState)
  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  const aeroInfoRepository = useInjection<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)

  const baseMapsLinks: Record<BaseMapType, string> = useMemo(
    () => ({
      ROAD: 'mapbox://styles/cristianonexatlas/cm7je64g200ki01qsa9de63jf?optimize=true',
      'ROAD-DARK': 'mapbox://styles/cristianonexatlas/cm7je7opu00st01qv1fzzaoqu?optimize=true',
      TERRAIN: 'mapbox://styles/cristianonexatlas/cm7je6ugd001h01s5dbvk7se6?optimize=true',
      SAT: 'mapbox://styles/cristianonexatlas/clw9nhmev012901oxfbwv3uqq?optimize=true',
      'CL-SAT': 'mapbox://styles/cristianonexatlas/cm1hxmd5b005l01p61gmufh77?optimize=true'
    }),
    []
  )

  const mapStyle = useMemo(() => {
    return baseMapsLinks[mapLayerState.baseMap]
  }, [mapLayerState.baseMap])

  return (
    <MapView
      style={mapStyle}
      defaultPosition={{ center: Coordinates.create({ latitude: 0, longitude: 0 }), zoom: 0.8 }}
    >
      <MapStackPresenter />
      <MapChartsPresenter />
      <MapAirspacesPresenter />
      <MapMeasuringSegmentPresenter />
      <MapMeteorologyPresenter />
      <MapRoutePresenter />
      {routeState.activeRoute && (
        <TrackHeadboardPresenter
          adhps={routeState.activeRoute.waypoints
            .filter((waypoint) => waypoint instanceof ADHP)
            .map((wpt) => aeroInfoRepository.getADHPData(wpt.code))
            .filter((wpt) => !!wpt)}
        />
      )}
      <MapSearchPresenter />
      <MapADHPPresenter />
      <MapProcedurePresenter />
      <MapTargetPresenter />
    </MapView>
  )
}

export { MapPresenter }
