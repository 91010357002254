type AirspaceType =
  | 'adiz'
  | 'afis'
  | 'atz'
  | 'ctr'
  | 'fir'
  | 'firSector'
  | 'fiz'
  | 'fcaAd'
  | 'fca'
  | 'fcaReh'
  | 'suaDangerous'
  | 'suaProhibited'
  | 'suaRestricted'
  | 'tma'
  | 'tmaSector'

const airspaceTypes: AirspaceType[] = [
  'adiz',
  'afis',
  'atz',
  'ctr',
  'fir',
  'firSector',
  'fiz',
  'fcaAd',
  'fca',
  'fcaReh',
  'suaDangerous',
  'suaProhibited',
  'suaRestricted',
  'tma',
  'tmaSector'
]

export type { AirspaceType }
export { airspaceTypes }
