export const ignoreMapClickLayers = [
  'meteorology-sigmet-layer-line',
  'meteorology-sigmet-layer-fill',

  'airspace-adiz-layer-border-line',
  'airspace-afis-layer-border-line',
  'airspace-fir-layer-border-line',
  'airspace-firSector-layer-border-line',
  'airspace-tma-layer-border-line',
  'airspace-tmaSector-layer-border-line',
  'airspace-ctr-layer-border-line',
  'airspace-atz-layer-border-line',
  'airspace-fiz-layer-border-line',
  'airspace-fca-layer-border-line',
  'airspace-fcaReh-layer-border-line',
  'airspace-fcaAd-layer-border-line',
  'airspace-suaDangerous-layer-border-line',
  'airspace-suaProhibited-layer-border-line',
  'airspace-suaRestricted-layer-border-line',

  'airspace-adiz-layer-symbol',
  'airspace-afis-layer-symbol',
  'airspace-fir-layer-symbol',
  'airspace-firSector-layer-symbol',
  'airspace-tma-layer-symbol',
  'airspace-tmaSector-layer-symbol',
  'airspace-ctr-layer-symbol',
  'airspace-atz-layer-symbol',
  'airspace-fiz-layer-symbol',
  'airspace-fca-layer-symbol',
  'airspace-fcaReh-layer-symbol',
  'airspace-fcaAd-layer-symbol',
  'airspace-suaDangerous-layer-symbol',
  'airspace-suaProhibited-layer-symbol',
  'airspace-suaRestricted-layer-symbol',

  'adhp-layer',
  'adhp-military-helipads',
  'adhp-helipads',
  'adhp-military-helipads-focused',
  'adhp-helipads-focused',
  'adhp-military-aerodromes-unpaved',
  'adhp-aerodromes-unpaved',
  'adhp-military-aerodromes-unpaved-focused',
  'adhp-aerodromes-unpaved-focused',
  'adhp-military-aerodromes-paved',
  'adhp-aerodromes-paved',
  'adhp-military-aerodromes-paved-focused',
  'adhp-aerodromes-paved-focused',
  'adhp-user-waypoints',
  'adhp-user-waypoints-focused',

  'map-procedure-chart-close-button-layer',
  'map-procedure-chart-opacity-button-layer',
  'map-procedure-chart-layer',

  'route-waypoint-label',
  'route-waypoints-point'
]
