import { useEffect } from 'react'

import { BootstrapError } from '@/constants/BootstrapErrors'

import { Container, ErrorMessage, ErrorTitle } from './BootstrapError.styles'

interface BootstrapErrorProps {
  error: BootstrapError
}

function BootstrapErrorScreen(props: BootstrapErrorProps) {
  const { error } = props

  useEffect(() => {
    if (error.type === 'killScreen') {
      setTimeout(() => {
        window.location.reload()
      }, 20000)
    } else {
      setTimeout(() => {
        error.action()
      }, 2000)
    }
  }, [error.type])

  return (
    <Container>
      <ErrorTitle>{error.title}</ErrorTitle>
      <ErrorMessage>{error.message}</ErrorMessage>
    </Container>
  )
}

export { BootstrapErrorScreen }
