/* global __APP_VERSION__ */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'

import { IconButton } from '@nexds/web'
import moment from 'moment'

import { InjectionTokens } from '@/controller/tokens'

import { AuthState, AuthStateProps } from '@/domain/states/AuthState'
import { LogoutUseCase } from '@/domain/useCases/Auth/logout'

import { IHttpClient } from '@/infra/http/IHttpClient'
import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import {
  Body,
  Container,
  Divider,
  InfoText,
  LinkA,
  LinkButton,
  Section,
  SectionTitle,
  Title,
  TitleContainer
} from './ConfigurationScreen.styles'

interface DataAndChartsUpdateResponse {
  dataUpdate: {
    id: number
    code: number
    start_date: string
    valid_to: string
  }
}

type Mapper = {
  [chave: string]: string
}
interface ConfigurationScreenProps {
  screensTitleMapper: Mapper
}

function ConfigurationScreen(props: ConfigurationScreenProps) {
  const { screensTitleMapper } = props

  const navigate = useNavigate()
  const appLocation = useLocation()
  const { t } = useI18n()

  const params = useParams()
  const httpClient = useInjection<IHttpClient>(InjectionTokens.HttpClient)
  const [authState] = useGlobalState<AuthState, AuthStateProps>(InjectionTokens.AuthState)

  const [lastUpdate, setLastUpdate] = useState<DataAndChartsUpdateResponse['dataUpdate']>(null)

  useEffect(() => {
    const fetch = async () => {
      const data = await httpClient.get<DataAndChartsUpdateResponse>(
        `${import.meta.env.VITE_NEXATLAS_API_URL}/dataUpdate/getLast`
      )

      if (data.success) {
        setLastUpdate(data.response.dataUpdate)
      }
    }

    fetch()
  }, [])

  const user = useMemo(() => authState.user, [authState.user])

  const processedUserInfo = useMemo(() => {
    const isTrial = user.trialTo && moment(user.trialTo) > moment() && user.subscriptionStatus === ''

    let subscriptionType = ''
    let isSubscriptionTypeWarning = false
    if (user.managerId && user.manager) {
      if (user.manager.subscriptionStatus === 'paid') {
        subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_DEPENDENT-ACCOUNT', {
          email: user.manager && user.manager.email
        })
      } else if (user.manager.subscriptionStatus === 'canceled') {
        subscriptionType = t(
          'DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_MANAGEMENT-ACCOUNT_SUBSCRIPTION-CANCELED',
          { email: user.manager && user.manager.email }
        )
      } else {
        subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_MANAGEMENT-ACCOUNT_PAYMENT-PENDING', {
          email: user.manager && user.manager.email
        })
      }
    } else if (isTrial) {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_TRIAL')
    } else if (user.subscriptionStatus === 'paid') {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_ACTIVE-SUBSCRIPTION')
    } else if (user.subscriptionStatus === 'pending_payment') {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_WAITING-PAYMENT')
    } else if (user.subscriptionStatus === 'unpaid') {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_PENDING-PAYMENT')
      isSubscriptionTypeWarning = true
    } else if (user.subscriptionStatus === 'canceled') {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_SUBSCRIPTION-CANCELED')
      isSubscriptionTypeWarning = true
    } else if (user.subscriptionStatus === 'ended') {
      subscriptionType = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TYPE_SUBSCRIPTION-EXPIRED')
      isSubscriptionTypeWarning = true
    }

    let paymentMethod = ''
    if (user.subscriptionStatus !== 'canceled' && user.subscriptionStatus !== 'ended' && !user.managerId) {
      if (user.subscriptionMethod === 'credit_card') {
        paymentMethod = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_PAYMENT-METHOD_CREDIT-CARD', {
          CardLastDigits: user.subscriptionCardLast
        })
      } else if (user.subscriptionMethod === 'boleto') {
        paymentMethod = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_PAYMENT-METHOD_INVOICE')
      }
    }

    const havePlan = user.subscriptionStatus !== 'canceled' && user.subscriptionStatus !== 'ended' && user.plan
    const planText = user?.plan?.text
    const isSubscriptionActive =
      !isTrial &&
      user.subscriptionStatus !== 'canceled' &&
      user.subscriptionStatus !== 'ended' &&
      user.subscriptionPeriodEnd
    const nextCollectionText = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_ACTIVE_NEXT-BILLING', {
      date: moment(user.subscriptionPeriodEnd).format('DD/MM/YYYY')
    })
    const trialEndText = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_ACTIVE_END-OF-TRIAL', {
      date: moment(user.trialTo).format('DD/MM/YYYY')
    })
    const isSubscriptionCanceled = user.subscriptionStatus === 'canceled' && user.subscriptionPeriodEnd
    const accessUntilText = t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_ACTIVE_ACCESS-LIMIT', {
      date: moment(user.subscriptionPeriodEnd).format('DD/MM/YYYY')
    })
    const needInvoiceSecondCopy =
      user.subscriptionStatus === 'unpaid' && user.subscriptionMethod === 'boleto' && user.subscriptionBoletoUrl
    const HaveDependants = user.plan && user.plan.dependants > 0
    const haveSpecialDependants = (user.subscriptionSpecialDependants ?? 0) > 0
    const CanUnsubscribe =
      !isTrial && user.subscriptionStatus !== 'canceled' && user.subscriptionStatus !== 'ended' && !user.managerId

    return {
      isTrial,
      trialEndText,
      subscriptionType,
      isSubscriptionTypeWarning,
      paymentMethod,
      havePlan,
      planText,
      isSubscriptionActive,
      nextCollectionText,
      isSubscriptionCanceled,
      accessUntilText,
      needInvoiceSecondCopy,
      HaveDependants,
      haveSpecialDependants,
      CanUnsubscribe
    }
  }, [authState.user])

  const title = useMemo(() => {
    const path = appLocation.pathname
    const level = path.split('/').length

    const isDefaultSettingsPath = screensTitleMapper[path] || level === 2
    const hasParamName = Object.keys(params).find((key) => key === 'name')
    const isWaypointEditPath = path.includes('user-waypoints') && path.includes('edit') && hasParamName

    if (isDefaultSettingsPath) return screensTitleMapper[path] ?? t('DRAWER_SETTINGS_MAIN_TITLE')
    if (isWaypointEditPath) return params['name']
  }, [appLocation.pathname, screensTitleMapper])

  const handleLogout = useCallback(() => {
    new LogoutUseCase().execute()
  }, [])

  const handleReturn = () => {
    navigate(-1)
  }

  return (
    <Container>
      {title !== t('DRAWER_SETTINGS_MAIN_TITLE') ? (
        <>
          <TitleContainer>
            <IconButton icon="ArrowLeft" onClick={handleReturn} size="xs" color="ghost" />
            <Title>{title}</Title>
          </TitleContainer>
          <Outlet />
        </>
      ) : (
        <>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <Body>
            <Section>
              <SectionTitle>{t('DRAWER_SETTINGS_MAIN_SECTION_ACCOUNT_TITLE')}</SectionTitle>
              <InfoText>{user.profile && `${user.profile.name} ${user.profile.surname}`}</InfoText>
              <InfoText>{user && user.email}</InfoText>
              <LinkA target="_blank" href={import.meta.env.VITE_ACCOUNT_MANAGER_URL}>
                {t('DRAWER_SETTINGS_MAIN_SECTION_ACCOUNT_MANAGE-ACCOUNT')}
              </LinkA>
              <LinkButton onClick={handleLogout}>{t('DRAWER_SETTINGS_MAIN_SECTION_ACCOUNT_LOGOUT')}</LinkButton>
            </Section>
            <Divider />
            <Section>
              <SectionTitle>{t('DRAWER_SETTINGS_MAIN_SECTION_CUSTOMIZATION_TITLE')}</SectionTitle>
              <LinkButton
                onClick={() => {
                  navigate(RouterPaths.SETTINGS__USER_WAYPOINTS)

                  sendMetrics('PLANNINGAPP_DRAWER_SETTINGS-USER-WAYPOINTS_CLICKED')
                }}
              >
                {t('DRAWER_SETTINGS_MAIN_SECTION_CUSTOMIZATION_USER-WAYPOINTS')}
              </LinkButton>
              <LinkButton
                onClick={() => {
                  navigate(RouterPaths.SETTINGS__ROUTE_WAYPOINT_INSERTION_MODE)
                }}
              >
                {t('DRAWER_SETTINGS_MAIN_SECTION_CUSTOMIZATION_ROUTE-INSERTION-MODE')}
              </LinkButton>
            </Section>
            <Divider />
            <Section>
              <SectionTitle>{t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_TITLE')}</SectionTitle>
              <InfoText variant={processedUserInfo.isSubscriptionTypeWarning ? 'warning' : 'default'}>
                {processedUserInfo.subscriptionType}
              </InfoText>
              {processedUserInfo.havePlan && <InfoText>{processedUserInfo.planText}</InfoText>}
              {processedUserInfo.isTrial && <InfoText>{processedUserInfo.trialEndText}</InfoText>}
              {processedUserInfo.isSubscriptionActive && <InfoText>{processedUserInfo.nextCollectionText}</InfoText>}
              {processedUserInfo.isSubscriptionCanceled && <InfoText>{processedUserInfo.accessUntilText}</InfoText>}
              {processedUserInfo.isSubscriptionActive && user.subscriptionMethod === 'boleto' && (
                <InfoText>{t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_ACTIVE_WAITING-PAYMENT')}</InfoText>
              )}
              <LinkA target="_blank" href={import.meta.env.VITE_SUBSCRIPTION_MANAGER_URL}>
                {t('DRAWER_SETTINGS_MAIN_SECTION_SUBSCRIPTION_MANAGE')}
              </LinkA>
            </Section>
            <Divider />
            <Section>
              <SectionTitle>{t('DRAWER_SETTINGS_MAIN_SECTION_DATA-AND-CHARTS_TITLE')}</SectionTitle>
              <InfoText>
                {t('DRAWER_SETTINGS_MAIN_SECTION_DATA-AND-CHARTS_UPDATED-UNTIL', {
                  date: lastUpdate && ` ${moment(lastUpdate.valid_to).format('DD-MM-YYYY')}`
                })}
              </InfoText>
              <LinkButton
                onClick={() => {
                  navigate(RouterPaths.SETTINGS__DATA_AND_CHARTS)
                }}
              >
                {t('DRAWER_SETTINGS_MAIN_SECTION_DATA-AND-CHARTS_MORE-INFO')}
              </LinkButton>
            </Section>
            <Divider />
            <Section>
              <SectionTitle>{t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_TITLE')}</SectionTitle>
              <InfoText>
                {t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_APPLICATION-NAME')} - {__APP_VERSION__}
              </InfoText>
              <InfoText>{t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_NEXATLAS-INFO')}</InfoText>
              <InfoText>{t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_NEXATLAS-CONTACT')}</InfoText>
              <LinkA target="_blank" href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}>
                {t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_TERMS-OF-SERVICE')}
              </LinkA>
              <LinkA target="_blank" href={import.meta.env.VITE_PRIVACY_POLICY_URL}>
                {t('DRAWER_SETTINGS_MAIN_SECTION_ABOUT_PRIVACY-POLICY')}
              </LinkA>
            </Section>
          </Body>
        </>
      )}
    </Container>
  )
}

export { ConfigurationScreen }
