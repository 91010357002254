export interface BootstrapError {
  code: string
  title: string
  message: string
  type: 'action' | 'killScreen'
  action: () => void
}

export const BootstrapErrorDefault: BootstrapError = {
  code: 'DEFAULT',
  title: 'Erro',
  message: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
  type: 'killScreen',
  action: () => {}
}

export const BootstrapErrors: BootstrapError[] = [
  {
    code: 'NO_INTERNET_CONNECTION',
    title: 'Sem conexão com a internet',
    message: 'Verifique sua conexão e tente novamente',
    type: 'killScreen',
    action: () => {}
  },
  {
    code: 'AUTHENTICATION_COOKIE_NOT_FOUND',
    title: 'Sessão expirada!',
    message: 'Por favor, faça login novamente',
    type: 'action',
    action: () => (document.location = import.meta.env.VITE_LOGIN_URL)
  },
  {
    code: 'SUBSCRIPTION_NOT_OK',
    title: 'Há um problema com a sua assinatura!',
    message: 'Estamos te redirecionando para solucionar o problema',
    type: 'action',
    action: () => (document.location = import.meta.env.VITE_SUBSCRIPTION_MANAGER_URL)
  },
  {
    code: 'ACCOUNT_UNAVAILABLE',
    title: 'Instabilidade temporária',
    message:
      'Estamos com uma instabilidade temporária. Nosso time já está trabalhando para resolver o problema. Tente novamente em poucos minutos.',
    type: 'killScreen',
    action: () => {}
  },
  {
    code: 'ACCOUNT_TOKEN_ERROR',
    title: 'Sessão expirada!',
    message: 'Por favor, faça login novamente',
    type: 'action',
    action: () => (document.location = import.meta.env.VITE_LOGIN_URL)
  }
]
