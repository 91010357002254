import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import { IHttpClient } from '@/infra/http/IHttpClient'
import { ILogger } from '@/infra/logger/ILogger'

export interface ReviewProps {
  rating?: number
  type: 'suggestion' | 'bug'
  feature:
    | 'general'
    | 'map'
    | 'routeOnMap'
    | 'routePanel'
    | 'routesSaved'
    | 'procedureCharts'
    | 'market'
    | 'mapCharts'
    | 'baseMap'
    | 'meteorology'
    | 'airspaces'
    | 'pointsOnMap'
    | 'ruler'
    | 'fcaAd'
  comment: string
  version: string
  product: 'planning-app'
}

export class SendReviewUseCase implements UseCase<ReviewProps, Result<void>> {
  private httpClient: IHttpClient
  private authState: AuthState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.httpClient = injectionContainer.get<IHttpClient>(InjectionTokens.HttpClient)
    this.authState = injectionContainer.get<AuthState>(InjectionTokens.AuthState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  async execute(reviewBody: ReviewProps): Promise<Result<void>> {
    try {
      const response = await this.httpClient.post(`${import.meta.env.VITE_NEXATLAS_API_URL}/reviews`, reviewBody, {
        headers: {
          Authorization: `Token ${this.authState.getStateSnapshot().token}`
        }
      })

      if (response.success === false) return Result.fail(response.error)
      if (response.status < 200 || response.status > 300) return Result.fail('Error sending review')
      return Result.ok()
    } catch (error) {
      this.logger.error('Error sending review', String(error))
      return Result.fail('Error sending review')
    }
  }
}
