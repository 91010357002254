import { SerializableState } from './SerializableState'

export type BaseChartType = 'WAC' | 'ENRCH' | 'ENRCL'
export type OverlayChartType = 'REA' | 'ARC'
export type Overlay2ChartType = 'REH'
export type BaseMapType = 'ROAD' | 'ROAD-DARK' | 'TERRAIN' | 'SAT' | 'CL-SAT'

export interface MapLayerStateProps {
  // Configs
  nightMode: boolean

  // Map
  baseMap: BaseMapType

  // Charts
  baseChart: BaseChartType
  overlayChart: OverlayChartType
  overlay2Chart: Overlay2ChartType

  // Airspace
  adiz: boolean
  afis: boolean
  fir: boolean
  firSector: boolean
  tma: boolean
  tmaSector: boolean
  ctr: boolean
  atz: boolean
  fiz: boolean
  fca: boolean
  fcaReh: boolean
  fcaAd: boolean
  suaDangerous: boolean
  suaProhibited: boolean
  suaRestricted: boolean
}

const INITIAL_STATE: MapLayerStateProps = {
  nightMode: !(new Date().getHours() > 6 && new Date().getHours() < 18),

  // Map
  baseMap: 'ROAD',

  // Charts
  baseChart: 'WAC',
  overlayChart: null,
  overlay2Chart: null,

  // Airspace
  adiz: false,
  afis: false,
  fir: false,
  firSector: false,
  tma: false,
  tmaSector: false,
  ctr: false,
  atz: false,
  fiz: false,
  fca: false,
  fcaReh: false,
  fcaAd: false,
  suaDangerous: false,
  suaProhibited: false,
  suaRestricted: false
}

export class MapLayerState extends SerializableState<MapLayerStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setNightMode(nightMode: boolean) {
    this._updateStateProp('nightMode', nightMode)
  }

  setLayer(layer: keyof MapLayerStateProps, value: boolean) {
    this._updateStateProp(layer, value)
  }

  // Base map
  setBaseMap(baseMap: BaseMapType) {
    this._updateStateProp('baseMap', baseMap)
  }

  // Charts
  setBaseChart(baseChart: BaseChartType) {
    this._updateStateProp('baseChart', baseChart)
  }

  setOverlayChart(overlayChart: OverlayChartType) {
    this._updateStateProp('overlayChart', overlayChart)
  }

  setOverlay2Chart(overlay2Chart: Overlay2ChartType) {
    this._updateStateProp('overlay2Chart', overlay2Chart)
  }

  // Airspace
  setAdiz(adiz: boolean) {
    this._updateStateProp('adiz', adiz)
  }

  setAfis(afis: boolean) {
    this._updateStateProp('afis', afis)
  }

  setAtz(atz: boolean) {
    this._updateStateProp('atz', atz)
  }

  setCtr(ctr: boolean) {
    this._updateStateProp('ctr', ctr)
  }

  setFir(fir: boolean) {
    this._updateStateProp('fir', fir)
  }

  setFirSector(firSector: boolean) {
    this._updateStateProp('firSector', firSector)
  }

  setFiz(fiz: boolean) {
    this._updateStateProp('fiz', fiz)
  }

  setFca(fca: boolean) {
    this._updateStateProp('fca', fca)
  }

  setFcaReh(fcaReh: boolean) {
    this._updateStateProp('fcaReh', fcaReh)
  }

  setFcaAd(fcaAd: boolean) {
    this._updateStateProp('fcaAd', fcaAd)
  }

  setSuaDangerous(suaDangerous: boolean) {
    this._updateStateProp('suaDangerous', suaDangerous)
  }

  setSuaProhibited(suaProhibited: boolean) {
    this._updateStateProp('suaProhibited', suaProhibited)
  }

  setSuaRestricted(suaRestricted: boolean) {
    this._updateStateProp('suaRestricted', suaRestricted)
  }

  setTma(tma: boolean) {
    this._updateStateProp('tma', tma)
  }

  setTmaSector(tmaSector: boolean) {
    this._updateStateProp('tmaSector', tmaSector)
  }

  isChartActive(): boolean {
    const snapshot = this.getStateSnapshot()
    return !!(snapshot.baseMap !== 'ROAD' || snapshot.baseChart || snapshot.overlayChart || snapshot.overlay2Chart)
  }

  isAirspaceActive(): boolean {
    const snapshot = this.getStateSnapshot()
    return (
      snapshot.adiz ||
      snapshot.afis ||
      snapshot.atz ||
      snapshot.ctr ||
      snapshot.fir ||
      snapshot.firSector ||
      snapshot.fiz ||
      snapshot.fca ||
      snapshot.fcaReh ||
      snapshot.fcaAd ||
      snapshot.suaDangerous ||
      snapshot.suaProhibited ||
      snapshot.suaRestricted ||
      snapshot.tma ||
      snapshot.tmaSector
    )
  }

  getKey(): string {
    return 'mapLayer'
  }
}
