import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const ModalContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: ${appZIndex.MODAL};
`

export const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.secondaryD2};
  opacity: 0.6;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`
