/* global __APP_VERSION__ */

import { useCallback, useMemo, useState } from 'react'

import { Assets, MenuGroup, MenuItem, TextField } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { AirspaceType } from '@/domain/protocols/Airspaces'
import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'
import { ReviewProps, SendReviewUseCase } from '@/domain/useCases/General/SendReviewUseCase'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { GlobalToastUtil } from '@/utils/globalToastUtil'
import { ModalUtil } from '@/utils/modalUtil'

import { LoadingStateMutator } from '../../../states/LoadingState'
import { ToolContainer } from '../ToolbarTools.styles'
import { Form, Icon } from './AirspacesTool.styles'

function AirspacesTool() {
  const VERSION = __APP_VERSION__
  const { t } = useI18n()
  const [mapLayerState, mapLayerStateMutator] = useGlobalState<MapLayerState, MapLayerStateProps>(
    InjectionTokens.MapLayerState
  )

  const handleAirspaces = useCallback(
    (mapLayerName: AirspaceType, concurrent?: AirspaceType) => {
      mapLayerStateMutator.setLayer(mapLayerName, !mapLayerState[mapLayerName])
      if (concurrent) mapLayerStateMutator.setLayer(concurrent, false)
    },
    [mapLayerState, mapLayerStateMutator]
  )

  const handleShowSuggestionModal = useCallback(() => {
    const handleSendFcaAdSuggestion = async (adCode: string, frequency: string) => {
      LoadingStateMutator.setIsLoading(true)
      const suggestionBody: ReviewProps = {
        feature: 'fcaAd',
        comment: `AD: ${adCode} - Freq: ${frequency}`,
        type: 'suggestion',
        version: VERSION,
        product: 'planning-app'
      }

      const result = await new SendReviewUseCase().execute(suggestionBody)

      if (result.isFailure) {
        GlobalToastUtil.send({
          label: t('TOAST_FCA-AD-SUGGESTION_ERROR'),
          variant: 'error',
          dismissible: true
        })
        return
      }

      GlobalToastUtil.send({
        label: t('TOAST_FCA-AD-SUGGESTION_SUCCESS'),
        variant: 'primary',
        rightIcon: 'Check'
      })

      LoadingStateMutator.setIsLoading(false)
    }

    ModalUtil.global.show({
      title: t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_SUGGESTION_MODAL_TITLE'),
      inputs: [
        {
          id: 'adCode',
          label: t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_SUGGESTION_MODAL_FIRST-FIELD'),
          placeholder: 'Ex: SKNX',
          value: '',
          valueToUpperCase: true
        },
        {
          id: 'frequency',
          label: t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_SUGGESTION_MODAL_SECOND-FIELD'),
          placeholder: 'Ex: 135.40',
          value: ''
        }
      ],
      maxWidth: 336,
      hasCloseButton: true,
      positiveColor: 'primary',
      onPositive: (_values) => {
        handleSendFcaAdSuggestion(_values[0].value, _values[1].value)
      },
      positiveLabel: t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_SUGGESTION_MODAL_BUTTON')
    })
  }, [])

  return (
    <ToolContainer gap={12}>
      <MenuGroup>
        <MenuItem
          key="fir"
          title={t('TOOLBAR_AIRSPACES_ITEM_FIR_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.fir}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'fir',
              active: !mapLayerState.fir // active is the new state
            })
            handleAirspaces('fir', 'firSector')
          }}
        />
        <MenuItem
          key="firSector"
          title={t('TOOLBAR_AIRSPACES_ITEM_FIR-SECTOR_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.firSector}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'firSector',
              active: !mapLayerState.firSector // active is the new state
            })
            handleAirspaces('firSector', 'fir')
          }}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuItem
          key="tma"
          title={t('TOOLBAR_AIRSPACES_ITEM_TMA_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.tma}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'tma',
              active: !mapLayerState.tma // active is the new state
            })
            handleAirspaces('tma')
          }}
        />
        <MenuItem
          key="tmaSector"
          title={t('TOOLBAR_AIRSPACES_ITEM_TMA-SECTOR_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.tmaSector}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'tmaSector',
              active: !mapLayerState.tmaSector // active is the new state
            })
            handleAirspaces('tmaSector')
          }}
        />
      </MenuGroup>
      <MenuItem
        key="ctr"
        title={t('TOOLBAR_AIRSPACES_ITEM_CTR_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.ctr}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'ctr',
            active: !mapLayerState.ctr // active is the new state
          })
          handleAirspaces('ctr')
        }}
      />
      <MenuItem
        key="atz"
        title={t('TOOLBAR_AIRSPACES_ITEM_ATZ_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.atz}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'atz',
            active: !mapLayerState.atz // active is the new state
          })
          handleAirspaces('atz')
        }}
      />
      <MenuItem
        key="fiz"
        title={t('TOOLBAR_AIRSPACES_ITEM_FIZ_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.fiz}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'fiz',
            active: !mapLayerState.fiz // active is the new state
          })
          handleAirspaces('fiz')
        }}
      />
      <MenuItem
        key="afis"
        title={t('TOOLBAR_AIRSPACES_ITEM_AFIS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.afis}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'afis',
            active: !mapLayerState.afis // active is the new state
          })
          handleAirspaces('afis')
        }}
      />
      <MenuGroup>
        <MenuItem
          key="fcaAd"
          title={t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.fcaAd}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'fcaAd',
              active: !mapLayerState.fcaAd // active is the new state
            })
            handleAirspaces('fcaAd')
          }}
        />
        <MenuItem
          key="fcaAdSuggestion"
          title={t('TOOLBAR_AIRSPACES_ITEM_FCA_AD_SUGGESTION_LABEL')}
          size="sm"
          rightDecorator={
            <Icon>
              <Assets.Plus size={'sm'} color={'#FCFCFC'} />
            </Icon>
          }
          onClick={() => {
            handleShowSuggestionModal()
            // sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            //   extra: 'fcaAdSugger',
            // })
          }}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuItem
          key="fca"
          title={t('TOOLBAR_AIRSPACES_ITEM_FCA_REA_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.fca}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'fca',
              active: !mapLayerState.fca // active is the new state
            })
            handleAirspaces('fca', 'fcaReh')
          }}
        />
        <MenuItem
          key="fcaReh"
          title={t('TOOLBAR_AIRSPACES_ITEM_FCA_REH_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapLayerState.fcaReh}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: 'fcaReh',
              active: !mapLayerState.fcaReh // active is the new state
            })
            handleAirspaces('fcaReh', 'fca')
          }}
        />
      </MenuGroup>
      <MenuItem
        key="suaDangerous"
        title={t('TOOLBAR_AIRSPACES_ITEM_SUA-DANGEROUS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.suaDangerous}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'suaDangerous',
            active: !mapLayerState.suaDangerous // active is the new state
          })
          handleAirspaces('suaDangerous')
        }}
      />
      <MenuItem
        key="suaProhibited"
        title={t('TOOLBAR_AIRSPACES_ITEM_SUA-PROHIBITED_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.suaProhibited}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'suaProhibited',
            active: !mapLayerState.suaProhibited // active is the new state
          })
          handleAirspaces('suaProhibited')
        }}
      />
      <MenuItem
        key="suaRestricted"
        title={t('TOOLBAR_AIRSPACES_ITEM_SUA-RESTRICT_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.suaRestricted}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'suaRestricted',
            active: !mapLayerState.suaRestricted // active is the new state
          })
          handleAirspaces('suaRestricted')
        }}
      />
      <MenuItem
        key="adiz"
        title={t('TOOLBAR_AIRSPACES_ITEM_ADIZ_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapLayerState.adiz}
        onClick={() => {
          sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
            extra: 'adiz',
            active: !mapLayerState.adiz // active is the new state
          })
          handleAirspaces('adiz')
        }}
      />
    </ToolContainer>
  )
}

export { AirspacesTool }
