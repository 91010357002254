import { PropsWithChildren, useEffect, useState } from 'react'

import { ImageSource } from 'mapbox-gl'

import { MapboxCoordinates } from '@/domain/protocols/Coordinates'

import { useMapContext } from './MapContext'

interface MapRasterSourceProps {
  id: string
  tiles?: string[]
  url?: string
  tileSize?: number
  coordinates?: MapboxCoordinates
}

function MapRasterSource(props: PropsWithChildren<MapRasterSourceProps>) {
  const { id, tiles, url, tileSize = 256, coordinates } = props

  const [sourceLoaded, setSourceLoaded] = useState(false)
  const { map } = useMapContext()

  // Add source logic
  useEffect(() => {
    if (!map) return
    if (!tiles && !url) return

    if (tiles) {
      map.addSource(id, {
        type: 'raster',
        tiles,
        tileSize
      })
    }

    if (url) {
      map.addSource(id, {
        type: 'image',
        url,
        coordinates
      })
    }

    setSourceLoaded(true)

    return () => {
      map?.removeSource(id)
    }
  }, [map])

  useEffect(() => {
    if (!map) return
    if (!sourceLoaded) return

    if (url && coordinates) {
      const source = map.getSource(id) as ImageSource
      source.updateImage({ url, coordinates })
    }
  }, [url, coordinates])

  if (!sourceLoaded) return null

  return <>{props.children}</>
}

export { MapRasterSource }
