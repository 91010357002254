import { useEffect, useMemo, useState } from 'react'

import GeoJSON from 'geojson'

import { InjectionTokens } from '@/controller/tokens'

import { AirspaceType, airspaceTypes } from '@/domain/protocols/Airspaces'
import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'

import { IAirspacesRepository } from '@/data/AirspacesRepository/IAirspacesRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'

import { MapActiveAirspace } from '../components/MapAirspace/MapActiveAirspace'
import { MapAirspace } from '../components/MapAirspace/MapAirspace'
import { MapImperativeInterface } from '../interfaces/MapImperativeInterface'
import { FeatureInfoState, FeatureInfoStateMutator } from '../states/FeatureInfoState'
import { MeasuringSegmentState } from '../states/MapMeasuringSegmentState'

interface SelectedAirspaceType {
  airspaceType: AirspaceType
  code: string
}

type MapFeatures = Record<AirspaceType, GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>>

function MapAirspacesPresenter() {
  const { t } = useI18n()
  const airspaceRepository = useInjection<IAirspacesRepository>(InjectionTokens.AirspaceRepository)
  const [mapLayerState] = useGlobalState<MapLayerState, MapLayerStateProps>(InjectionTokens.MapLayerState)
  const featureInfoState = useBehaviorSubject(FeatureInfoState)
  const measuringSegmentState = useBehaviorSubject(MeasuringSegmentState)

  const makeAirspaceTitle = (name: string, type?: AirspaceType): string => {
    switch (type) {
      case 'adiz':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_ADIZ')} ${name}`
      case 'afis':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_AFIS')} ${name}`
      case 'fir':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FIR')} ${name}`
      case 'firSector':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FIR-SECTOR')} ${name}`
      case 'tma':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_TMA')} ${name}`
      case 'tmaSector':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_TMA-SECTOR')} ${name}`
      case 'ctr':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_CTR')} ${name}`
      case 'atz':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_ATZ')} ${name}`
      case 'fiz':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FIZ')} ${name}`
      case 'fca':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FCA_REA')} ${name}`
      case 'fcaReh':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FCA_REH')} ${name}`
      case 'fcaAd':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_FCA_AD')} ${name}`
      case 'suaDangerous':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_DANGER-AREA')} ${name}`
      case 'suaProhibited':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_PROHIBITED-AREA')} ${name}`
      case 'suaRestricted':
        return `${t('AIRSPACES-INFO_FEATURE-TITLE_RESTRICTED-AREA')} ${name}`
      default:
        return name
    }
  }

  const [selectedAirspace, setSelectedAirspace] = useState<SelectedAirspaceType>(null)

  const mapFeatures = useMemo(() => {
    const features: MapFeatures = {} as MapFeatures
    airspaceTypes.forEach((airspaceName) => {
      const airspace = airspaceRepository.getAirspace(airspaceName)

      if (airspace.isFailure) {
        return
      }

      features[airspaceName] = airspaceRepository.getAirspace(airspaceName).getValue()
    })

    return features
  }, [airspaceRepository])

  useEffect(() => {
    if (featureInfoState.active?.type !== 'airspace') {
      setSelectedAirspace(null)
    }
  }, [featureInfoState.active])

  const activeAirspace = useMemo(() => {
    if (selectedAirspace) {
      const feature = airspaceRepository.getFeatureByCode(selectedAirspace.airspaceType, selectedAirspace.code)

      if (feature) {
        return feature.getValue()
      }
    }

    return null
  }, [selectedAirspace, airspaceRepository])

  useEffect(() => {
    if (activeAirspace) {
      if (activeAirspace.bbox) {
        MapImperativeInterface.flyToBounds(
          activeAirspace.bbox.slice(0, 4) as [number, number, number, number],
          [150, 100, 150, 100],
          500
        )
      }

      FeatureInfoStateMutator.showAirspace(
        makeAirspaceTitle(activeAirspace.properties.name ?? '', selectedAirspace?.airspaceType),
        activeAirspace
      )
    } else {
      FeatureInfoStateMutator.hide()
    }
  }, [activeAirspace])

  const handleAirspacePress = (airspaceType: AirspaceType, code: string) => {
    if (measuringSegmentState.active) return

    setSelectedAirspace((prev) => {
      if (prev?.code === code && prev?.airspaceType === airspaceType) {
        return null
      }

      return { airspaceType, code }
    })
  }

  const getAirspaceId = (airspaceName: AirspaceType, type: 'source' | 'layer') => {
    if (type === 'source') return `airspace-${airspaceName}-source`
    else return `airspace-${airspaceName}-layer`
  }

  return (
    <>
      {airspaceTypes.map(
        (airspaceName: AirspaceType) =>
          mapLayerState[airspaceName] &&
          mapFeatures[airspaceName] && (
            <MapAirspace
              key={airspaceName}
              airspaceId={airspaceName}
              borderSourceId={`${getAirspaceId(airspaceName, 'source')}-border`}
              borderLayerId={`${getAirspaceId(airspaceName, 'layer')}-border`}
              symbolSourceId={`${getAirspaceId(airspaceName, 'source')}-symbol`}
              symbolLayerId={`${getAirspaceId(airspaceName, 'layer')}-symbol`}
              features={mapFeatures[airspaceName]}
              onPress={(code: string) => handleAirspacePress(airspaceName, code)}
            />
          )
      )}
      {selectedAirspace && selectedAirspace && (
        <MapActiveAirspace
          type={selectedAirspace.airspaceType}
          feature={activeAirspace}
          fillSourceId={`airspace-source-fill`}
          fillLayerId={`airspace-layer-fill`}
        />
      )}
    </>
  )
}

export { MapAirspacesPresenter }
