import { useEffect, useRef } from 'react'

import { Toast } from '@nexds/web'
import styled from 'styled-components'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { appZIndex } from '@/presentation/utils/zIndexMapper'
import { globalToastSubject, GlobalToastUtil } from '@/utils/globalToastUtil'

function GlobalToastPresenter() {
  const toasts = useBehaviorSubject(globalToastSubject)

  const finishTimeout = useRef<NodeJS.Timeout>()

  const currentToast = toasts?.[0]

  useEffect(() => {
    if (!currentToast) return

    finishTimeout.current = setTimeout(() => {
      if (currentToast.onFinish) {
        currentToast.onFinish()
      }

      GlobalToastUtil.hide(currentToast.id)
    }, currentToast.timeDelay)
  }, [currentToast])

  if (!currentToast) return null

  return (
    <Container bottom={15}>
      <Toast
        leftIcon={currentToast.leftIcon}
        rightIcon={currentToast.rightIcon}
        label={currentToast.label}
        actionLabel={currentToast.actionLabel}
        variant={currentToast.variant}
        size={currentToast.size}
        dismissible={currentToast.dismissible}
        onAction={() => {
          if (currentToast.onAction) {
            const shouldRunFinish = currentToast.onAction()
            if (!shouldRunFinish) {
              clearTimeout(finishTimeout.current)
            }
          }

          GlobalToastUtil.hide(currentToast.id)
        }}
      />
    </Container>
  )
}

export { GlobalToastPresenter }

interface ContainerProps {
  bottom?: number
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  bottom: ${({ bottom }) => bottom ?? 0}px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  max-width: 335px;
  padding: 0 8px;
  z-index: ${appZIndex.GLOBAL_TOAST};
`
