/* global __APP_VERSION__ */

import 'reflect-metadata'
import ReactDOM from 'react-dom/client'

import { InjectionProvider } from '@contexts/InjectionContext.tsx'
import { InversionContainer } from '@controller/container.ts'
import { LifeCycle } from '@controller/lifeCycle.ts'
import isPropValid from '@emotion/is-prop-valid'
import { ILogger } from '@infra/logger/ILogger'
import { LogLevel } from '@infra/logger/LogLevels.ts'
import { ThemeProvider } from '@nexds/web'
import { App } from '@presentation/App.tsx'
import { GlobalStyle } from '@presentation/global.styles.tsx'
import * as Sentry from '@sentry/react'
import { StyleSheetManager } from 'styled-components'
import { registerSW } from 'virtual:pwa-register'

import { BootstrapErrorDefault, BootstrapErrors } from './constants/BootstrapErrors'
import { InjectionTokens } from './controller/tokens'
import { BootstrapErrorScreen } from './presentation/modules/Base/components/BootstrapError/BootstrapError'
import { SplashScreen } from './presentation/modules/Base/components/SplashScreen/SplashScreen'

// A helper to conditionally forward props
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

function reloadApp() {
  window.location.reload()
}

/**
 * Registers the service worker.
 * When an update is needed, it listens for the controllerchange event
 * and reloads the app as soon as the new service worker takes control.
 */
async function registerServiceWorker(): Promise<boolean> {
  let newVersionAvailable = false

  const updateSW = registerSW({
    immediate: true,
    onNeedRefresh: () => {
      console.log('[ServiceWorker] New version detected.')
      newVersionAvailable = true

      const watchdogTimer = setTimeout(() => {
        console.warn('[Watchdog] No controller change detected. Forcing reload.')
        reloadApp()
      }, 7500)

      // Listen for the new service worker to take control.
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        clearTimeout(watchdogTimer)
        console.log('[ServiceWorker] New controller detected. Reloading app.')
        reloadApp()
      })
    }
  })

  // Wait until the service worker update check is complete.
  await updateSW(true)
  return newVersionAvailable
}

const bootstrap = async () => {
  console.log(`[Bootstrap] Bootstrapping app version ${__APP_VERSION__}, mode: ${import.meta.env.MODE}`)

  // Optionally, reload the app if the connection goes back online.
  window.addEventListener('online', reloadApp)

  Sentry.init({
    dsn: 'https://f53c62663e7c414999ad4bd73b39e7af@sentry.nexatlas.com/19',
    integrations: [Sentry.browserTracingIntegration()],
    release: __APP_VERSION__,
    tracesSampleRate: 0.1
  })

  // Register the service worker and check for updates.
  const updateAvailable = await registerServiceWorker()

  // If an update is available, show a splash screen until the new SW takes control.
  if (updateAvailable) {
    const loadingRoot = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    loadingRoot.render(
      <ThemeProvider theme="dark">
        <GlobalStyle />
        <SplashScreen />
      </ThemeProvider>
    )
    // The page will reload as soon as the new service worker activates,
    // so we do not continue with bootstrapping.
    return
  }

  // Continue with bootstrapping if no update is pending.
  const loadingRoot = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  loadingRoot.render(
    <ThemeProvider theme="dark">
      <GlobalStyle />
      <SplashScreen />
    </ThemeProvider>
  )

  // Initialize dependency injection and lifecycle components.
  const container = InversionContainer.getInstance().getContainer()
  const logger = container.get<ILogger>(InjectionTokens.Logger)
  logger.setLogLevel(import.meta.env.MODE === 'production' ? LogLevel.Warn : LogLevel.Debug)

  const lifeCycle = new LifeCycle(container)
  const initializationResult = await lifeCycle.initializeComponents()

  if (initializationResult.isFailure) {
    logger.error('Bootstrap', initializationResult.error)
    let errorHandler =
      BootstrapErrors.find((error) => error.code === initializationResult.error) || BootstrapErrorDefault

    loadingRoot.unmount()
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <ThemeProvider theme="dark">
        <GlobalStyle />
        <BootstrapErrorScreen error={errorHandler} />
      </ThemeProvider>
    )
    return
  }

  // (Optional) A very short delay if needed.
  await new Promise((resolve) => setTimeout(resolve, 500))

  window.removeEventListener('online', reloadApp)
  loadingRoot.unmount()

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <InjectionProvider container={container}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme="dark">
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StyleSheetManager>
    </InjectionProvider>
  )
}

bootstrap()
