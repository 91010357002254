import styled from 'styled-components'

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -11px;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`
