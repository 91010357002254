import LineLabel from '@presentation/assets/route/line-label.png'
import WaypointLabelIcon from '@presentation/assets/route/waypoint-label.png'
import { CirclePaint, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl'
import { DefaultTheme } from 'styled-components'

import { MAP_SYMBOL_PRIORITIES } from './GeojsonUtils'

export const routeWaypointHighlightedCirclePaint = (theme: DefaultTheme): CirclePaint => ({
  'circle-radius': 20,
  'circle-color': theme.colors.secondary,
  'circle-stroke-width': 2,
  'circle-stroke-color': theme.colors.primary,
  'circle-opacity': 0.2
})

export const routeWaypointHighlightedPointPaint = (theme: DefaultTheme): CirclePaint => ({
  'circle-radius': 7,
  'circle-color': theme.colors.primary,
  'circle-stroke-width': 1.3,
  'circle-stroke-color': theme.colors.secondary,
  'circle-opacity': 1
})

export const routeWaypointCirclePaint = (theme: DefaultTheme): CirclePaint => ({
  'circle-radius': ['get', 'radius'],
  'circle-color': [
    'match',
    ['get', 'state'],
    'default',
    theme.colors.primary,
    'current',
    theme.colors.primaryL2,
    'past',
    theme.colors.neutralL2,
    theme.colors.primary
  ],
  'circle-stroke-width': 1.2,
  'circle-stroke-color': theme.colors.secondary,
  'circle-opacity': 0.85
})

export const routeWaypointLabelLayout = (): SymbolLayout => ({
  'text-field': ['get', 'code'],
  'text-size': 12,
  'text-justify': 'center',
  'text-font': ['Open Sans Semibold'],
  'text-rotation-alignment': 'viewport',
  'text-keep-upright': true,
  'icon-keep-upright': true,
  'text-offset': [0, -1.65],
  'icon-rotation-alignment': 'viewport',
  'symbol-sort-key': ['get', 'priority'],
  'symbol-z-order': 'source',
  'icon-text-fit': 'both',
  'icon-image': WaypointLabelIcon,
  'icon-text-fit-padding': [5, 10, 10, 10],
  'text-ignore-placement': true
})

export const routeWaypointLabelPaint = (theme: DefaultTheme): SymbolPaint => ({
  'text-color': theme.colors.neutralL5,
  'icon-opacity': ['get', 'labelOpacity'],
  'text-opacity': ['get', 'textOpacity']
})

export const routeLinePaint = (theme: DefaultTheme): LinePaint => ({
  'line-width': 5,
  'line-color': [
    'match',
    ['get', 'state'],
    'default',
    theme.colors.primary,
    'current',
    theme.colors.primaryL2,
    'past',
    theme.colors.neutralL2,
    theme.colors.primary
  ],
  'line-opacity': 0.95
})

export const routeLineRightBorderPaint = (theme: DefaultTheme): LinePaint => ({
  'line-width': 1.5,
  'line-color': theme.colors.secondary,
  'line-offset': 3,
  'line-opacity': ['match', ['get', 'state'], 'past', 0.75, 0.85]
})

export const routeLineLeftBorderPaint = (theme: DefaultTheme): LinePaint => ({
  'line-width': 1.5,
  'line-color': theme.colors.secondary,
  'line-offset': -3,
  'line-opacity': ['match', ['get', 'state'], 'past', 0.75, 0.85]
})

export const routeLineLabelLayout = (): SymbolLayout => ({
  'text-field': ['get', 'label'],
  'text-rotate': ['get', 'headingText'],
  'text-size': 12.5,
  'text-justify': 'center',
  'text-font': ['Open Sans Bold'],
  'text-rotation-alignment': 'map',
  'text-ignore-placement': true,
  'text-offset': [0, 0],
  'text-allow-overlap': true,
  'icon-rotation-alignment': 'map',
  'icon-text-fit': 'both',
  'icon-image': LineLabel,
  'icon-rotate': ['get', 'heading'],
  'icon-text-fit-padding': [2, 13, 2, 6],
  'symbol-sort-key': MAP_SYMBOL_PRIORITIES.ROUTE_LENGTH_HEADING
})

export const routeLineLabelDefaultPaint = (theme: DefaultTheme): SymbolPaint => ({
  'text-color': theme.colors.neutralL4
})
