import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { CoordWaypoint, Route, Waypoint, WaypointType } from '@/domain/models'
import { AuthState } from '@/domain/states/AuthState'
import { BaseChartType, Overlay2ChartType, OverlayChartType } from '@/domain/states/MapLayerState'

import { TranslationTokens, VarsByToken } from '@/i18n/ITranslations'
import { magneticBearing } from '@/utils/coordinates'
import { normalizeForSearch, numberToFixedLength } from '@/utils/string'

const resolveIdFromWaypointType = (waypointType: WaypointType, waypoint: Waypoint) => {
  if (waypointType.codeName === 'ADHP') {
    if (waypoint.extra?.type === 'AD') return 1
    if (waypoint.extra?.type === 'HP') return 5
  }

  if (waypointType.codeName === 'City') return 2
  if (waypointType.codeName === 'CoordWaypoint') return 3
  if (waypointType.codeName === 'AwWaypoint') return 4

  if (waypointType.codeName === 'Navaid') {
    if (waypoint.extra?.type === 'NDB') return 6
    if (waypoint.extra?.type === 'VOR') return 7
    if (waypoint.extra?.type === 'VOR/DME') return 8
  }

  if (waypointType.codeName === 'VisualPosition') return 9

  return 3
}

const decimalToDMS = (D: number, lng: boolean) => {
  let dir
  if (D === 0) dir = lng ? 'W' : 'S'
  else dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N'

  const posAbs = Math.abs(D)

  let deg = Math.floor(posAbs)

  const minFloat = (posAbs - deg) * 60.0000000001
  const min = Math.floor(minFloat)

  const sec = ((minFloat - min) * 60).toFixed(2)

  if (deg < 0) deg = -deg

  return `${dir}${deg}° ${min}' ${sec}"`
}

const addStringPad = (number: string, size: number) => {
  let s = String(number)

  while (s.length < (size || 2)) {
    s = `0${s}`
  }

  return s
}

type ChartFirst = OverlayChartType | Overlay2ChartType
type ChartSecond = BaseChartType

export function handleGenerateNavLog(
  activeRoute: Route,
  chartOptionsFirst: ChartFirst,
  chartOptionsSecond: ChartSecond,
  t: <T extends TranslationTokens>(...args: VarsByToken[T] extends never ? [T] : [T, VarsByToken[T]]) => string
) {
  if (!activeRoute || !activeRoute.waypoints) return

  const magneticBearings = []
  for (let i = 0; i < activeRoute.waypoints.length - 1; i++) {
    magneticBearings.push(
      magneticBearing(activeRoute.waypoints[i].coordinates, activeRoute.waypoints[i + 1].coordinates)
    )
  }

  const waypoints = activeRoute.waypoints.map((waypoint) => {
    const coordWaypointName = waypoint.customName ? waypoint.customName : t('WAYPOINT_TYPE_COORD-WAYPOINT')
    const rtr = {
      name: waypoint instanceof CoordWaypoint ? coordWaypointName : waypoint.getDisplayName(),
      latitude: waypoint.coordinates.latitude,
      longitude: waypoint.coordinates.longitude
    }

    return rtr
  })

  const mapLayers = []

  if (chartOptionsSecond) {
    mapLayers.push(chartOptionsSecond)
  } else {
    mapLayers.push('WAC')
  }

  if (chartOptionsFirst) {
    mapLayers.push(chartOptionsFirst)
  }

  const injectionContainer = InversionContainer.getInstance().getContainer()
  const authState = injectionContainer.get<AuthState>(InjectionTokens.AuthState).getStateSnapshot()

  const params = [
    `name=${activeRoute.name}`,
    `waypoints=${encodeURI(JSON.stringify(waypoints))}`,
    `magnetic=${encodeURI(JSON.stringify(magneticBearings))}`,
    `charts=${encodeURI(JSON.stringify(mapLayers))}`,
    `token=${authState.token}`
  ]

  if (activeRoute.groundSpeed) {
    params.push(`gs=${activeRoute.groundSpeed}`)
  }

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  a.href = `${import.meta.env.VITE_NEXATLAS_API_URL}/navigationRoute/navigationLog?${params.join('&')}`
  a.target = '_blank'
  a.click()
}

export function generateGPX(routeName: string, points: Waypoint[]) {
  const parsedPoints = points.reduce(
    (total, point) =>
      `${total}<trkpt lat="${point.coordinates.latitude}" lon="${point.coordinates.longitude}"><name>${point.getDisplayName()}</name></trkpt>`,
    ''
  )

  const GPX = `<gpx><metadata><name>NexAtlas | ${routeName}</name><author>NexAtlas</author><time>${new Date().toISOString()}</time></metadata><trk><name>${routeName}</name><trkseg>${parsedPoints}</trkseg></trk></gpx>`

  return GPX
}

const TYPE_CODE = ['', 'Airport', 'User', 'User', 'Intersection', 'Airport', 'NDB', 'VOR', 'VOR', 'User']

/**
 * Função para o MS FS2020 (.pln) [REMOVER OU MELHORAR ESSE JAVADOC]
 *
 * @param routeName
 * @param points
 * @param midair
 * @returns
 */
export function generatePLN(routeName: string, points: Waypoint[], midair: boolean) {
  const parsedWaypoints = points.map((waypoint) => {
    const waypointTypeId = resolveIdFromWaypointType(waypoint.waypointType, waypoint)
    const plnType = TYPE_CODE[waypointTypeId]
    const waypointCode = `${Math.abs(waypoint.coordinates.latitude * 1000).toFixed(0)}_${addStringPad(Math.abs(waypoint.coordinates.longitude * 10).toFixed(0), 4)}`

    return {
      plnType,
      code: waypoint.code ? normalizeForSearch(waypoint.code).replace(' ', '_') : waypointCode,
      icaoRegion: 'SB',
      latitude: decimalToDMS(waypoint.coordinates.latitude, false),
      longitude: decimalToDMS(waypoint.coordinates.longitude, true),
      special: false
    }
  })

  const firstWaypoint = parsedWaypoints[0]
  const lastWaypoint = parsedWaypoints[parsedWaypoints.length - 1]

  let special = midair

  if (firstWaypoint.plnType !== 'Airport' || midair) {
    firstWaypoint.code = 'CUSTD'
    firstWaypoint.plnType = 'Intersection'
    firstWaypoint.special = true
    special = true
  }

  if (lastWaypoint.plnType !== 'Airport' || midair) {
    lastWaypoint.code = 'CUSTA'
    lastWaypoint.plnType = 'Intersection'
    lastWaypoint.special = true
    special = true
  }

  const parsedXMLPoints = parsedWaypoints.reduce(
    (total, point) => `${total}
        <ATCWaypoint id="${point.code.substring(0, 10)}">
            <ATCWaypointType>${point.plnType}</ATCWaypointType>
            <WorldPosition>${point.latitude},${point.longitude},+005000.00</WorldPosition>${
              point.plnType !== 'User'
                ? `
            <ICAO>
                ${point.plnType !== 'Airport' && !point.special ? `<ICAORegion>${point.icaoRegion}</ICAORegion>` : ''}
                <ICAOIdent>${point.code}</ICAOIdent>
            </ICAO>`
                : ''
            }
        </ATCWaypoint>`,
    ''
  )

  const PLN = `<?xml version="1.0" encoding="UTF-8"?>
<SimBase.Document Type="AceXML" version="1,0">
    <Descr>AceXML Document</Descr>
    <FlightPlan.FlightPlan>
        <Title>${routeName}</Title>
        <FPType>VFR</FPType>
        <RouteType>HighAlt</RouteType>
        <CruisingAlt>11000</CruisingAlt>
        <DepartureID>${firstWaypoint.code}</DepartureID>
        <DepartureLLA>${firstWaypoint.latitude},${firstWaypoint.longitude},+005000.00</DepartureLLA>
        <DestinationID>${lastWaypoint.code}</DestinationID>
        <DestinationLLA>${lastWaypoint.latitude},${lastWaypoint.longitude},+005000.00</DestinationLLA>
        <Descr>${firstWaypoint.code}, ${lastWaypoint.code}</Descr>
        <DepartureName>${special ? 'Custom departure' : firstWaypoint.code}</DepartureName>
        <DestinationName>${special ? 'SSUH' : lastWaypoint.code}</DestinationName>
        <AppVersion>
            <AppVersionMajor>11</AppVersionMajor>
            <AppVersionBuild>282174</AppVersionBuild>
        </AppVersion>${parsedXMLPoints}
    </FlightPlan.FlightPlan>
</SimBase.Document>
`

  return PLN
}

const CODES = [0, 1, 28, 28, 11, 1, 2, 3, 3, 28]

/**
 *
 * @param {{
 *  latitude: number;
 *  longitude: number;
 * }} point
 * @return {string}
 */
function getWaypointName(point: Waypoint) {
  const [latInteger, latDecimal] = Math.abs(point.coordinates.latitude).toString().split('.')
  const [lonInteger, lonDecimal] = Math.abs(point.coordinates.longitude).toString().split('.')

  const latitude = `${point.coordinates.latitude >= 0 ? '+' : '-'}${numberToFixedLength(latInteger, 2, '0')}.${numberToFixedLength(latDecimal, 3, '0')}`
  const longitude = `${point.coordinates.longitude >= 0 ? '+' : '-'}${numberToFixedLength(lonInteger, 3, '0')}.${numberToFixedLength(lonDecimal, 3, '0')}`

  return `${latitude}_${longitude}`
}

/**
 * Função para o XPLANE versão {parâmetro version}
 *
 * @param waypoints
 * @param version
 * @returns
 */
export function generateFMS(waypoints: Waypoint[], version: number) {
  const parsedWaypoints = waypoints.map((waypoint) => {
    const waypointTypeId = resolveIdFromWaypointType(waypoint.waypointType, waypoint)
    const fmsType = CODES[waypointTypeId]

    return {
      fmsType,
      code: fmsType === 28 ? getWaypointName(waypoint) : waypoint.code,
      latitude: waypoint.coordinates.latitude.toFixed(6),
      longitude: waypoint.coordinates.longitude.toFixed(6)
    }
  })

  if (version === 11) {
    const firstWaypoint = parsedWaypoints[0]
    const lastWaypoint = parsedWaypoints[parsedWaypoints.length - 1]

    const firstRouteType = firstWaypoint.fmsType === 1 ? 'ADEP' : 'DEP'
    const lastRouteType = lastWaypoint.fmsType === 1 ? 'ADES' : 'DES'

    const departure = `${firstRouteType} ${firstWaypoint.code}`
    const destination = `${lastRouteType} ${lastWaypoint.code}`

    const FMSWaypoints = parsedWaypoints.reduce((total, waypoint, index) => {
      let routeCode

      if (index === 0) {
        routeCode = firstRouteType
      } else if (index === parsedWaypoints.length - 1) {
        routeCode = lastRouteType
      } else {
        routeCode = 'DRCT'
      }

      return `${total}\n${waypoint.fmsType} ${waypoint.code} ${routeCode} 0 ${waypoint.latitude} ${waypoint.longitude}`
    }, '')

    const FMS = `I\n1100 Version\nCYCLE 2009\n${departure}\n${destination}\nNUMENR ${parsedWaypoints.length}${FMSWaypoints}`

    return FMS
  }

  const parsedPoints = parsedWaypoints.reduce(
    (total, waypoint) => `${total}\n${waypoint.fmsType} ${waypoint.code} 0 ${waypoint.latitude} ${waypoint.longitude}`,
    ''
  )

  const FMS = `I\n3 version\n1\n${parsedWaypoints.length}${parsedPoints}`

  return FMS
}
