import { Icon } from '@nexds/web'
import { BehaviorSubject } from 'rxjs'

export interface GlobalToastItem {
  id: string
  leftIcon?: Icon
  rightIcon?: Icon
  label: string
  actionLabel?: string
  variant?: 'dark' | ' light' | 'primary' | 'secondary' | 'error'
  size?: 'sm' | 'md' | 'lg'
  timeDelay?: number
  dismissible?: boolean
  zIndex?: number
  onAction?: () => boolean
  onFinish?: () => void
}

const DEFAULT_PROPS: GlobalToastItem = {
  id: '',
  leftIcon: null,
  rightIcon: null,
  label: '',
  actionLabel: null,
  variant: 'secondary',
  size: 'sm',
  timeDelay: 3000,
  dismissible: false,
  onAction: () => {
    return true
  },
  onFinish: () => {}
}

const globalToastSubject = new BehaviorSubject<GlobalToastItem[]>([])

const GlobalToastUtil = {
  show: (item: GlobalToastItem) => {
    globalToastSubject.next([...globalToastSubject.getValue(), item])
  },

  hide: (id: string) => {
    const globalToastItems = globalToastSubject.getValue()
    const index = globalToastItems.findIndex((item) => item.id === id)
    if (index === -1) return
    globalToastItems.splice(index, 1)
    globalToastSubject.next([...globalToastItems])
  },

  send: (globalToastProps: Partial<GlobalToastItem>) => {
    GlobalToastUtil.show({
      ...DEFAULT_PROPS,
      id: `sent-${new Date().getTime()}`,
      ...globalToastProps
    })
  },

  subscribe: (callback: (items: GlobalToastItem[]) => void) => {
    return globalToastSubject.subscribe(callback)
  }
}

export { globalToastSubject, GlobalToastUtil }
